<template>
    <component :is="SkinComponent" v-model="form" :code="code" @save="save"></component>
</template>

<script>
import api from "@/api";
import { initForm } from "@/assets/variables";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initForm(),
    }),
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!!this.$store.state.payload?._user) {
                const { user } = await api.v1.me.get();
                this.form.writer = user;

                const { name, name_first, name_last, phone, email, postcode, address1, address2 } = user;
                this.form.meta = { ...this.form.meta, name, phone, name_first, name_last, email, postcode, address1, address2 };
            }

            const { code } = this;
            this.form = initForm({ ...this.form, code });
        },

        async save() {
            const { files = [], ...form } = this.form;
            const { form: { _id: _form } = {} } = await api.v1.forms.post(form);
            for (const [index, file] of files.entries()) {
                await api.v1.forms.files.post({ _form, index }, file);
            }
            alert("Your inquiry has been submitted.");
            // this.$router.go(-1);
        },
    },
};
</script>
